import {
  Chart,
  ArcElement,
  PieController,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";

Chart.register(
  PieController,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
  ArcElement,

);

let chart;

const init = (el, data) => {
  if (!chart) {
    chart = new Chart(el, {
      type: "pie",
      data:{
        labels: data.labels,
        datasets: data.datasets,
      },
      options:{
        maintainAspectRatio: false 
      }

    });
  } else {data.datasets
    data.datasets[0].data.forEach((dataset, i) => {
        chart.data.datasets[0].data[i] = dataset
    })
    // chart.data.datasets = data.datasets
    chart.update();
  }

};

export default init;
