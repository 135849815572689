// import { io } from "socket.io-client";
const hueServer = "https://hue-ws.vipdeskconnect.com";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
import ws from "./ws";
import drawChart from "./drawChart";

let destroyed = false

let idleStates = {
  active: "Engaged",
  idle: "Idle",
  locked: "Locked",
  disconnected: "Disconnected",
  extendedIdle: "Extended Idle",
  unknown: "Unknown",
};

let userSessions = {};
let disconnectTimeout = null;
let department = null;
let groupBy = null;
let sizeBy = null;
let container = document.body;
// let socket;
let data;
let chartRefreshTimer;
let callbacks = [];
let version;

const colors = {
  idle: "orange",
  extendedIdle: "red",
  active: "green",
  locked: "#AAAAAA",
  disconnected: "#000000",
  unknown: "#CCCCCC",
};

const getGroupBy = (user) => {
  let idleState = user?.events?.idle?.idleState || "unknown";
  let powerState = user?.events?.power?.powerState || "unknown";
  if (powerState === "disconnected") {
  console.log('********************')
  
    idleState = powerState
  }

  switch (groupBy) {
    case "idle":
      return idleStates[idleState] || idleState;
    case "activeApp":
      return user?.events?.activeApp?.name || "unknown";
    case "department":
      return user.session?.department || "unknown";
    case "version":
      return user.session?.version || "unknown";
    case "ip":
      return user.session?.ipAddress || "unknown";
    default:
      //default to idle
      return idleStates[idleState] || idleState;
  }
};
const getSizeBy = (user, times) => {
  switch (sizeBy) {
    case "currentDuration":
      return times.idleDuration || 0;
    case "activePercent":
      return times.activeTimePercentage ? times.activeTimePercentage * times.activeTimePercentage  : 0;
      case "idleStateTimePercentage":
        return times.idleStateTimePercentage ? times.idleStateTimePercentage * times.idleStateTimePercentage * times.idleStateTimePercentage : 0;
      case "totalTimeInState":
      return times.totalIdleDurationInState || 0;
    case "sessionDuration":
      return times.sessionDuration || 0;
    default:
      //default to idle
      return times.idleDuration || 0;
  }
};

const generateData = () => {
  try {
    let result = {};
    let now = Date.now();

    Object.keys(userSessions).forEach((key) => {
      let now = Date.now();
      let user = userSessions[key];

      if (
        department &&
        department !== "all" &&
        user?.session?.department !== department
      ) {
        return;
      }

      if (version && user?.session?.version !== version) {
        return;
      }

      let idleState = user?.events?.idle?.idleState || "unknown";
      let powerState = user?.events?.power?.powerState || "unknown";
      if (powerState === "disconnected") idleState = powerState;

      let sessionDuration = (now - user.session.timestamp) / 1000;
      let idleDuration =
        (now -
          (user?.events?.idle?.stateChangeTimestamp ||
            user?.events?.idle?.timestamp ||
            now - 1000)) /
        1000;
      let idleDuration2 =
        (now - (user?.events?.idle?.timestamp || now - 1000)) / 1000;
      let activeTimePercentage = 0;
      // let idleStateTimePercentage = 0;
      let totalIdleDurationInState = 0;
      let activeDuration = 0;

      let idleStateTimePercentage =  (((user.events.idle.totalTime.idle + user.events.idle.totalTime.extendedIdle )/(user.events.idle.totalTime.total-user.events.idle.totalTime.locked))*100)

      activeTimePercentage = ((user.events.idle.totalTime.active/(user.events.idle.totalTime.total-user.events.idle.totalTime.locked))*100)
      if (idleState !== "unknown" && user?.events?.idle?.totalTime) {
        totalIdleDurationInState =
          user.events.idle.totalTime[user?.events?.idle?.idleState] +
          idleDuration;
        activeDuration = user.events.idle.totalTime.active;
        // if (idleState === "active") activeDuration += idleDuration;

        // activeTimePercentage = (activeDuration / sessionDuration) * 100;
        // idleStateTimePercentage =
        //   (totalIdleDurationInState / sessionDuration) * 100;
      }

      let stats = {
        activeTimePercentage,
        idleStateTimePercentage,
        sessionDuration,
        idleDuration,
        idleDuration2,
        totalIdleDurationInState,
        activeDuration,
        totalTime: user.events.idle.totalTime
      };

      let value =
        getSizeBy(user, {
          activeTimePercentage,
          idleStateTimePercentage,
          sessionDuration,
          idleDuration,
          totalIdleDurationInState,
          activeDuration,
        }) + 10;

      let group = getGroupBy(user);
      let duration =
        now -
        (user?.events?.idle?.stateChangeTimestamp ||
          user?.events?.idle?.timestamp ||
          now);
      let minutes = (duration / 1000 / 60).toFixed(2);
      let time = dayjs(
        user?.events?.idle?.stateChangeTimestamp ||
          user?.events?.idle?.timestamp ||
          now
      ).fromNow(true);

      let payload = {
        name: (user.session.displayName || "Unknown") + (user.session.computerName || "Unknown"),
        label: (user.session.displayName || "Unknown") + ` ${minutes}mins`,

        value,
        title: `${user.session.displayName} (${
          user.session.department
        }) ${idleStates[idleState] ||
          idleState} for ${time} (${activeTimePercentage.toFixed(1)}%)`,
        user,
        group,
        stats,
        isIdle: user?.events?.idle?.isIdle,
        idleDurations: user?.events?.idle?.totalTime,
        idleState: idleStates[idleState] || idleState,
        sessionDuration,
        idleStateTimePercentage,
        activeTimePercentage,
        color: colors[idleState] || "#AAA",
      };

      if (payload.user.session.username === 'lynne.greenwalt'){
        console.log('********************')
        console.log(payload)
        console.log('********************')

      }
      if (!result[group]) {
        result[group] = [];
      }
      result[group].push(payload);
    });

    let idleDataset = {
      name: "Idle",
      children:
        Object.keys(result)
          .sort()
          .map((key) => {
            return {
              name: key,
              children: result[key].sort((a, b) => {
                return b.value - a.value ? 1 : -1;
              }),
            };
          }) || [],
    };
    data = idleDataset;
    let total = Object.keys(userSessions).length;
    let active = Object.keys(userSessions).filter((id) => {
      return userSessions[id]?.events?.idle?.idleState === "active";
    }).length;
    let locked = Object.keys(userSessions).filter((id) => {
      return userSessions[id]?.events?.idle?.idleState === "locked";
    }).length;
    let idle = Object.keys(userSessions).filter((id) => {
      return userSessions[id]?.events?.idle?.idleState === "idle";
    }).length;
    let extendedIdle = Object.keys(userSessions).filter((id) => {
      return userSessions[id]?.events?.idle?.idleState === "extendedIdle";
    }).length;

    let users = Object.keys(userSessions).map((id) => userSessions[id]);
    let totalTime = users.reduce((acc, user) => {
      return acc + (now - user?.events?.idle?.timestamp) / 1000;
    }, 0);

    let activeTime = users
      .filter((user) => user?.events?.idle?.idleState === "active")
      .reduce((acc, user) => {
        return acc + (now - user.events.idle.timestamp) / 1000;
      }, 0);
    let lockedTime = users
      .filter((user) => user?.events?.idle?.idleState === "locked")
      .reduce((acc, user) => {
        return acc + (now - user.events.idle.timestamp) / 1000;
      }, 0);
    let idleTime = users
      .filter((user) => user?.events?.idle?.idleState === "idle")
      .reduce((acc, user) => {
        return acc + (now - user.events.idle.timestamp) / 1000;
      }, 0);
    let extendedIdleTime = users
      .filter((user) => user?.events?.idle?.idleState === "extendedIdle")
      .reduce((acc, user) => {
        return acc + (now - user.events.idle.timestamp) / 1000;
      }, 0);

    let callbackPayload = {
      users,
      counts: {
        total,
        active,
        idle,
        extendedIdle,
        locked,
      },
      times: {
        total: totalTime,
        active: activeTime,
        idle: idleTime,
        extendedIdle: extendedIdleTime,
        locked: lockedTime,
      },
    };
    callbacks.forEach((callback) => callback(callbackPayload));

    return data;
  } catch (e) {
    console.log("CATCH", e);
    return undefined;
  }
};

let updateTimeout;

const updateChart = () => {
  clearTimeout(updateTimeout);
  console.log('updateChart')
  updateTimeout = setTimeout(() => {
    console.log('updateChartupdateChartupdateChart', container , destroyed)
    let data = generateData()
    drawChart(data, container , destroyed);
    destroyed = false;

    clearTimeout(chartRefreshTimer);
    chartRefreshTimer = setTimeout(() => {
      updateChart();
    }, 1000 * 60);
  }, 500);
};

let initted = false;

const init = (url = hueServer, el) => {
  console.log(url)
  destroyed = true
  if (initted) return;
  initted = true;

  document.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "visible") {
      updateChart();
    }
  });

  if (el) {
    container = el;
  }
  // socket = io(url);

  let wsSession = ws(hueServer,(event, payload)=>{
    switch(event){
      case 'connect':
        clearTimeout(disconnectTimeout);
        break
      case 'hueSessionCache':
        userSessions = payload || {};
        updateChart();
      break
      case 'disconnect':
        userSessions = {};
        updateChart();
      break
      case 'hueEvent':
        userSessions[payload.sid] = payload;
        if (payload.event === "idle" || payload.event === "power") {
          console.log(payload.event,payload.session.username)
          console.log('+++++++++++++', event, payload)

          updateChart();
        }
      break
      case 'hueConnect':
        userSessions[payload.sid] = payload;
        updateChart();
      break
      case 'hueDisconnect':
        delete userSessions[payload.sid];
        updateChart();
      break
    }
  })

  // socket.on("connect", () => {
  //   clearTimeout(disconnectTimeout);
  //   console.log("Connected");
  //   socket.emit("join", "hueEvents");
  // });

  // socket.on("hueSessionCache", (payload) => {
  //   userSessions = payload || {};
  //   updateChart();
  // });

  // socket.on("disconnect", () => {
  //   // disconnectTimeout = setTimeout(() => {
  //   userSessions = {};
  //   // }, 1000 * 30);
  //   updateChart();
  // });

  // socket.on("hueEvent", (payload) => {
  //   // console.log("hueEvent", payload);
  //   userSessions[payload.sid] = payload;
  //   if (payload.session.username === "brooke.whiteley") {
  //     console.log(payload);
  //   }
  //   if (payload.event === "idle" || payload.event === "power") {
  //     console.log(payload.event,payload.session.username)
  //     // userSessions[payload.sid] = payload;
  //     // if (payload.counts){
  //     //   if (payload?.events?.idle?.counts) {
  //     // console.log(payload);
  //     // }
  //     updateChart();
  //   }
  // });

  // socket.on("hueConnect", (payload) => {
  //   console.log("hueConnect", payload);
  //   userSessions[payload.sid] = payload;
  //   updateChart();
  // });

  // socket.on("hueDisconnect", (payload) => {
  //   console.log("hueDisconnect", payload);
  //   delete userSessions[payload.sid];
  //   updateChart();
  // });
  return {
    setContainer: (c) => {
      container = c;
    },
    setGroupBy: (g) => {
      groupBy = g;
      updateChart();
    },
    setSizeBy: (g) => {
      sizeBy = g;
      updateChart();
    },
    setVersion: (g) => {
      version = g;
      updateChart();
    },

    setDepartment: (d) => {
      department = d;
      updateChart();
    },
    destroy: () => {
      console.log('DESTROY')
      clearTimeout(chartRefreshTimer);
      // container.innerHTML = "";
      wsSession.socket.close();
      destroyed = true;
      initted = false;
      console.log('CLOSED')
    },
    onUpdate: (cb) => {
      callbacks.push(cb);
    },
  };
};

export default init;
