<template>
  <div class="flex-grow-1 d-flex" style="max-height: calc(100vh - 80px)">
    <v-card class="flex-grow-0 mr-2">
      <v-card-text>
        department {{ department }} groupBy {{ groupBy }} sizeBy {{ sizeBy }}
        <v-select
          :items="groupByOptions"
          v-model="groupBy"
          label="Group By..."
        ></v-select>

        <v-select
          :items="sizeByOptions"
          v-model="sizeBy"
          label="Size By..."
        ></v-select>

        <v-select
          :items="[{ text: `--ALL--`, value: `all` }, ...departmentOptions]"
          v-model="department"
          label="Department"
        ></v-select>
        <v-card>
          <v-card-text>
            <canvas ref="totals"></canvas>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>

    <v-card class="flex-grow-1 flex-column">
      <v-card-text
        class="d-flex flex-grow-1 flex-column"
        :style="{ height: '100%' }"
      >
      <!-- <canvas ref="barChart" height="400" width="400"></canvas> -->
      <div ref="tooltip" class="legend">
        <div class="active">Engaged: <div class="legendSwatch"></div></div>
        <div class="idle">Idle: <div class="legendSwatch"></div></div>
        <div class="extendedIdle">Extended Idle: <div class="legendSwatch"></div></div>
        <div class="locked">Locked: <div class="legendSwatch"></div></div>
        <div>Disconnected: <div class="legendSwatch"></div></div>
      </div>
        <div
          ref="svgContainer"
          class="flex-grow-1 pa-0 d-flex justify-center"
          style="overflow: hidden"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// import ws from "./ws";
import init from "./init";
import pieChart from "./PieChart";
// import barChart from "./barChart";

let chart;
export default {
  data() {
    return {
      chartData: null,
      version: '',
      sizeBy: "currentDuration",
      sizeByOptions: [
        { text: "Current Duration", value: "currentDuration" },
        { text: "% Time Engaged", value: "activePercent" },
        { text: "% Time idle", value: "idleStateTimePercentage" },
        { text: "Total Time in State", value: "totalTimeInState" },
        { text: "Session Duration", value: "sessionDuration" },
      ],
      groupByOptions: [
        {
          text: "Idle",
          value: "idle",
        },
        {
          text: "Department",
          value: "department",
        },
        {
          text: "Active App",
          value: "activeApp",
        },
        {
          text: "Version",
          value: "version",
        },
        {
          text: "IP Address",
          value: "ip",
        },
      ],
      groupBy: "idle",
      departmentOptions: [],
      department: "all",
    };
  },
  watch: {
      version(val){
          console.log('VERSION', val)
                chart.setVersion(val);
      },

    groupBy(val) {
      console.log('groupBy',val);
      if (val === "department") {
        this.department = 'all';
      }
      console.log(this.$route, val);
      if (this.$route.params.groupBy !== val) {
        this.$route.params.groupBy = val || 'idle';
        this.$route.params.sizeBy = this.sizeBy|| 'currentDuration';
        this.$route.params.department = this.department || 'all';
        this.$router.push(this.$route);
      }
      chart.setGroupBy(val);
    },
    sizeBy(val) {
      console.log('sizeBy',val);
      if (this.$route.params.sizeBy !== val) {
        // this.$route.params.groupBy = this.groupBy || 'idle';
        this.$route.params.sizeBy = this.sizeBy|| 'currentDuration';
        // this.$route.params.department = this.department || 'all';
        this.$router.push(this.$route);
      }
      chart.setSizeBy(val);
    },

    department(val) {
      if (val && val !== "all") {
        this.groupBy = "idle";
      }
      if (this.$route.params.department !== val) {
        // this.$route.params.groupBy = this.groupBy|| 'idle';
        // this.$route.params.sizeBy = this.sizeBy|| 'currentDuration';
        this.$route.params.department = this.department || 'all';
                this.$router.push(this.$route);
      }

      chart.setDepartment(val);
    },
  },
  beforeUnmount(){
        console.log('beforeUnmountbeforeUnmountbeforeUnmountbeforeUnmountbeforeUnmountbeforeUnmountbeforeUnmountbeforeUnmountbeforeUnmount')

    chart.destroy()
  },
  beforeDestroy(){
    console.log('beforeDestroybeforeDestroybeforeDestroybeforeDestroybeforeDestroybeforeDestroybeforeDestroybeforeDestroybeforeDestroybeforeDestroy')
        chart.destroy()

  },
  mounted() {
    this.$nextTick(() => {
      console.log('GPPPPP ',this.$refs.barChart)
      
      // ws(undefined, (event, payload, data) =>{
      //   console.log('************EVENT', event, payload, data)
      //   if (event === "hueEvent" && (payload.event === 'idle' || payload.event === 'power')){
      //     console.log(data)
      // barChart(this.$refs.barChart, data);
      //   }
      // })
        if (this.groupBy !== this.$route.params.groupBy) {
          this.groupBy = this.$route.params.groupBy;
        }
        if (this.sizeBy !== this.$route.params.sizeBy) {
          this.sizeBy = this.$route.params.sizeBy;
        }
        if (this.department !== this.$route.params.department) {
          this.department = this.$route.params.department ;
        }
        if (this.version !== this.$route.params.version) {
          this.version = this.$route.params.version;
        }

      chart = init(undefined, this.$refs.svgContainer, true);
      console.log('NEW CHART', chart)
      chart.onUpdate((data) => {
        this.chartData = data;
        let departmentOptions = {};
        data.users.forEach((user) => {
          departmentOptions[user.session.department] = true;
        });
        this.departmentOptions = Object.keys(departmentOptions).sort();

        pieChart(this.$refs.totals, {
          labels: ["Active", "Idle", "Extended Idle", "Locked"],
          datasets: [
            {
              label: "Idle Breakdown",
              data: [
                data.counts.active,
                data.counts.idle,
                data.counts.extendedIdle,
                data.counts.locked,
              ],
              backgroundColor: ["green", "orange", "red", "#CCC"],
              hoverOffset: 4,
            },
          ],
        });
      });
    });
  },
};
</script>

<style>
svg {
  margin: 0 auto;
}
.parent-circle {
  fill-opacity: 0.25;
  stroke-width: 2px;
}
.child-circle {
  fill-opacity: 0.8;
  stroke-width: 1.5px;
}
svg {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
}
/* .isIdle{
    fill: purple!important;
} */
.children text{
    fill: #fff;
}
text{
    cursor: default;
}

.tooltip{
    position: absolute;
    background-color: #fff;
    border-radius: 5px;
    padding: 5px;
    font-size: 0.8rem;
    font-family: Arial, Helvetica, sans-serif;
    box-shadow: 10px 10px 10px #000;
    
    }

  .legend{
    position: absolute;
    font-size: .8rem;
    line-height: .8rem;
  }  
  .legendSwatch{
    width: 10px;
    height: 10px;
    margin-right: 5px;
    display: inline-block;
    background-color: #000;
  }
  .active   .legendSwatch{
    background-color: green;
  }
  .idle   .legendSwatch{
    background-color: orange;
  }   
  .extendedIdle   .legendSwatch{
    background-color: red;
  }
  .locked   .legendSwatch{
    background-color: #CCC;
  }

</style>