import { io } from "socket.io-client";
const hueServer = "https://hue-ws.vipdeskconnect.com";

let userSessions = {};

const init = (url = hueServer, callback = () => {}) => {
  console.log({url});
  let socket;
  console.log(url, callback);
  socket = io(url);

  socket.on("connect", (payload) => {
    console.log("ON CONNECTON CONNECTON CONNECTON CONNECTON CONNECTON CONNECTON CONNECT");
    socket.emit("join", "hueEvents");
    callback("connected", payload,userSessions);
  });

  socket.on("hueSessionCache", (payload) => {
    callback("hueSessionCache", payload,userSessions);
    userSessions = payload || {};

  });

  socket.on("disconnect", (payload) => {
    console.log("ON disconnect");

    callback("disconnected", payload,userSessions);
  });

  socket.on("hueEvent", (payload) => {
    userSessions[payload.sid] = payload;

    // console.log("hueEvent", payload);
    console.log("hueEventhueEventhueEventhueEvent", payload);
    callback('hueEvent', payload,userSessions);
  });
  socket.on("hueConnect", (payload) => {
    userSessions[payload.sid] = payload;

    callback("hueConnect", payload,userSessions);
  });

  socket.on("hueDisconnect", (payload) => {
    delete userSessions[payload.sid];
    callback("hueDisconnect", payload,userSessions);
  });

  console.log("DONEDONEDONEDONEDONEDONEDONEDONEDONEDONEDONEDONEDONE!",socket.connect());
  socket.connect()
  return {
    socket,
    callback,
  };
};

export default init;
