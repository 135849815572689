
import {select} from "d3";

const insertLinebreaks = function (d){
    let el = select(this);
    let words = d.data.label.split(" ");
    el.text("");
    el.attr("y", (d) => `${(((d.r / 4) * -words.length) / 2) * 1.5}px`);
  
    for (let i = 0; i < words.length; i++) {
      el.append("tspan")
        .attr("x", 0)
        .attr("dy", (d) => (d.r / 5) * 1.5 + "px")
        .text(words[i]);
    }
    return el
  };
  

  export default insertLinebreaks;