import {select, pack} from "d3";
let defaultElement = document.body

const createChart = (el = defaultElement)=>{
      // el.style = "display:flex;flex-direction:column;";
      let svg = select(el).append("svg");
      let g = svg.append("g");
      let parents = g.append("g").attr("class", "parents");
      let children = g.append("g").attr("class", "children");
      return {container: el, svg, g, parents, children, pack: pack().padding((d) => d.height * 10)};
}
      
export default createChart;      
