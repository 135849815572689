import { hierarchy, schemeTableau10, select } from "d3";
import createChart from "./createChart";
import insertLinebreaks from "./insertLinebreaks";

const duration = 1000;

const d3Colors = schemeTableau10;

const colors = {
  idle: "orange",
  extendedIdle: "red",
  active: "green",
  locked: "#AAAAAA",
  disconnected: "#000000",
  unknown: "#CCCCCC",
};

let currentChart;
let root;
let container;
let div;

const drawChart = (data, el, force) => {
  console.log({force})
  if (!currentChart || container !== el ) {
    if (container) {
      container.innerHTML = "";
    }
    console.log('CREATE CHART')
    currentChart = createChart(el);
    container = el;
    div = select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("position", "absolute")
      .style("opacity", 0.5)

  }

  if (!data || !data.children?.length) {
    console.log('NO DATA')
    return;
  }

  console.log({currentChart},'currentChartcurrentChartcurrentChartcurrentChartcurrentChart',
  currentChart.container)

  console.log({currentChart},'currentChartcurrentChartcurrentChartcurrentChartcurrentChart',
  currentChart.container.hidden)


  if (document.hidden) {
    console.log("HIDDEN");
    return;
  }

  const width = currentChart.container.clientWidth;
  const height = currentChart.container.clientHeight;

  if (!width || !height) return console.log('No Width or height',{width, height});

  let diameter = Math.min(width, height);

  currentChart.svg.attr("width", diameter).attr("height", diameter);

  currentChart.pack.size([diameter, diameter]);

  root = hierarchy(data).sum((d) => d.value);

  const node = currentChart.pack(root);
  const parents = node.ancestors();
  const children = node.leaves();

  //SELECTION
  const parentCircles = currentChart.parents
    .selectAll(".parent-circle")
    .data(parents[0].children, (d) => d.data.name);

  const parentTexts = currentChart.parents
    .selectAll(".parent-circle-text")
    .data(parents[0].children, (d) => d.data.name || "Unknown");

  const childCircles = currentChart.children
    .selectAll(".child-circle")
    .data(children, (d) => d.data.name);

  //TRANSITION
  parentCircles
    .transition()
    .duration(duration)
    .attr("cx", (d) => d.x)
    .attr("cy", (d) => d.y)
    .attr("r", (d) => d.r);

  childCircles
    .transition()
    .duration(duration)
    .style("font-size", (d) => d.r / 4 + "px")
    .attr("transform", (d) => `translate(${d.x}, ${d.y})`)
    .select("circle")
    .attr("r", (d) => d.r)
    .attr("opacity", 1)
    .attr("color", (d) => d.data.color)
    .attr("idleState", (d) => d.data.idleState)
    .attr(
      "class",
      (d) =>
        `${d.data?.user?.events?.idle?.isIdle} idleState-${d.data?.user?.events?.idle?.idleState}`
    )
    .style("fill", (d) => d.data.color)
    .style("stroke", (d) => d.data.color);
  //    .attr("transform", `scale(1.3)` )
  //    .transition()
  //    .duration(duration)
  //    .attr("transform", `scale(1)` )
  //    .attr("opacity", 1)

  childCircles.select("text").each(insertLinebreaks);

  parentTexts
    .text((d) => (d.data.name || "Unknown") + ` (${d.data.children.length})`)

    .transition()
    .duration(duration)
    .attr("x", (d) => d.x)
    .attr("y", (d) => d.y - d.r)
    .style("font-size", (d) => d.r / 5 + "px");

  // EXIT
  parentCircles
    .exit()
    .transition()
    .duration(duration / 2)
    .attr("r", 1e-6)
    .remove();

  childCircles
    .exit()
    .transition()
    .duration(duration / 2)
    .attr("transform", (d) => `translate(${d.x}, ${d.y}) scale(5)`)
    .style("opacity", 0)
    .remove();

  parentTexts
    .exit()
    .transition()
    .duration(duration / 2)
    .attr("cx", 2000)
    .attr("cy", 2000)
    .remove();

  // ENTER

  //let newParentCircleGroup =
  parentCircles
    .enter()
    .append("g")
    .attr("class", "parent-circle-g");

  // newParentCircleGroup

  parentCircles
    .enter()
    .append("circle")
    .style("fill", () => d3Colors[0])
    .style("stroke", (d) => colors[d.data.name])
    .attr("class", "parent-circle")
    .attr("cx", (d) => d.x)
    .attr("cy", (d) => d.y)
    .attr("r", 1e-6)
    .transition()
    .duration(duration / 2)
    .attr("r", (d) => d.r);

  parentCircles
    .enter()
    .append("text")
    .text((d) => (d.data.name || "Unknown") + ` (${d.data.children.length})`)
    .attr("class", "parent-circle-text text")
    .attr("text-anchor", "middle")
    .attr("x", (d) => d.x)
    .attr("y", (d) => d.y - d.r)
    .transition()
    .duration(duration)
    .style("font-size", (d) => d.r / 5 + "px");

  let newChildCircleGroup = childCircles
    .enter()
    .append("g")
    .attr("class", "child-circle")
    .style("font-size", (d) => d.r / 4 + "px")
    .attr("text-anchor", "middle")
    .on("mousemove", (event) => {
      div.style("left", event.pageX + 28 + "px")
      .style("top", event.pageY - 28 + "px")
    })
    .on("mouseenter", (event,d) => {
      console.log(d);

      div
        .html( `<h3>${d?.data?.user?.session?.displayName} (${d?.data?.user?.session?.department})</h3><br/>
        <div>% Engaged: ${d.data.stats.activeTimePercentage.toFixed(2)}%</div>
        <div>% Idle: ${d.data.stats.idleStateTimePercentage.toFixed(2)}%</div>
        <div>Engaged Duration: ${(d.data.stats.totalTime.active/60/60).toFixed(2)} hours</div>
        <div>Idle Duration: ${(d.data.stats.totalTime.idle/60/60).toFixed(2)} hours</div>
        <div>Extended Idle Duration: ${(d.data.stats.totalTime.extendedIdle/60/60).toFixed(2)} hours</div>
        <div>Total Duration: ${(d.data.stats.totalTime.total/60/60).toFixed(2)} hours</div>
        <div>Locked Duration: ${(d.data.stats.totalTime.locked/60/60).toFixed(2)} hours</div>
        `)
        div.transition()
          .duration(200)
          .style("left", event.pageX + 28 + "px")
          .style("top", event.pageY - 28 + "px")
          .style("opacity", 1)
        })
    .on("mouseleave", () => {
      console.log('LEAVE')
      div
      .transition()
        .duration(500)
      .style("left", event.pageX + 100 + "px")
        .style("top", event.pageY - 100 + "px")
      .style("opacity", 0)


    });

    

  newChildCircleGroup
    .attr("transform", (d) => `translate(${d.x}, ${d.y}) scale(5)`)
    .style("opacity", 0)
    .transition()
    .duration(duration)
    .style("opacity", 1)
    .attr("transform", (d) => `translate(${d.x}, ${d.y}) scale(1)`);

  newChildCircleGroup
    .append("circle")
    .style("fill", (d) => d.data.color || colors[d.parent.data.name])
    .style("stroke", (d) => d.data.color || colors[d.parent.data.name])
    .attr("r", (d) => d.r)
    
  // newChildCircleGroup.append("title").text((d) => d.data.title);

  newChildCircleGroup

    .append("text")
    .text((d) => d.data.label || "Unknown")
    .each(insertLinebreaks);
};

export default drawChart;
